var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElTable',{attrs:{"data":_vm.transactions,"show-header":true}},[_c('el-table-column',{attrs:{"label":"Date","prop":"formatted_created_at","min-width":"40"}}),_c('el-table-column',{attrs:{"label":"Amount","min-width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transaction = ref.row;
return [(_vm.shouldBeDisplayed(transaction))?_c('strong',[_vm._v(_vm._s(transaction.amount))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Property","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transaction = ref.row;
return (transaction.unit)?[_c('router-link',{staticClass:"property-link",attrs:{"to":{ name: 'app.units.show', params: { id: transaction.unit.property_id, unit_id: transaction.unit.id } }}},[_vm._v(" "+_vm._s(transaction.unit.property.short_address)+" "),(transaction.unit.name)?_c('span',{staticClass:"unit-badge ml-1 text-gray-darker"},[_vm._v(" "+_vm._s(transaction.unit.name)+" ")]):_vm._e()])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"Ordered by"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transaction = ref.row;
return (transaction.unit)?[_c('img',{staticClass:"listing-manager-avatar",attrs:{"src":_vm.get(transaction, 'inspection.associated_business_user.avatar_file_url', transaction.unit.property.listing_manager.avatar_file_url)}}),_vm._v(" "+_vm._s(_vm.get(transaction, 'inspection.associated_business_user.full_name', transaction.unit.property.listing_manager.full_name))+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"Service","prop":"service","min-width":"110"}}),_c('el-table-column',{attrs:{"label":"Invoice","min-width":"35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var transaction = ref.row;
return (Boolean(_vm.getReceiptUrl(transaction)))?[_c('div',{staticClass:"text-center"},[_c('a',{attrs:{"href":_vm.getReceiptUrl(transaction),"target":"_blank"}},[_c('i',{staticClass:"sdicon-external-link font-24"})])])]:undefined}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }