<template>
  <SdPage
    id="transactions-page"
  >
    <SdPageHeader title="Transactions" />

    <div
      v-if="isAccountOwner"
      class="row justify-content-between align-items-center mt-2"
    >
      <div class="col-auto">
        <ElSelect
          v-model="currentDateRange"
          value-key="name"
          size="medium"
          @change="dateRangeChanged"
        >
          <ElOption
            v-for="dateRange in dateRanges"
            :key="dateRange.name"
            :value="dateRange"
            :label="dateRange.name"
          />
        </ElSelect>
      </div>
      <div class="col-auto">
        <ElButton
          size="small"
          @click="csvExport"
        >
          <div class="d-flex align-items-center">
            <i class="sdicon-export font-21" />
            Export to CSV
          </div>
        </ElButton>
      </div>
    </div>

    <SdLoadingLayer
      v-if="isAccountOwner"
      ref="loadingLayer"
      :init="fetchTransactions"
    >
      <template #loaded>
        <TransactionsList
          v-if="$viewport.lt.md"
          :transactions="transactions"
          class="mt-4"
        />
        <TransactionsTable
          v-else
          :transactions="transactions"
          class="mt-4"
        />
      </template>
    </SdLoadingLayer>
    <ElAlert
      v-else
      class="align-items-center mt-4"
      type="info"
      show-icon
      :closable="false"
    >
      <div>
        Only account owners can view this page, please contact your account owner.
      </div>
    </ElAlert>
  </SdPage>
</template>

<script>
import { ref } from '@vue/composition-api';
import TransactionsTable from '@/components/transactions/TransactionsTable';
import { getDateRanges, loadTransactions } from '@/utils/TransactionUtil';
import TransactionsList from '@/components/transactions/TransactionsList';
import Role from '@/constants/Role';

export default {
  name: 'Transactions',
  components: { TransactionsList, TransactionsTable },
  setup(props, context) {
    const { $store } = context.root;

    const dateRanges = ref(getDateRanges($store));
    const currentDateRange = ref(dateRanges.value[0]);
    const transactions = ref([]);
    const isAccountOwner = $store.state.Auth.user.role === Role.ACCOUNT_OWNER;

    return {
      currentDateRange,
      dateRanges,
      dateRangeChanged,
      fetchTransactions,
      transactions,
      csvExport,
      isAccountOwner,
    };

    async function fetchTransactions() {
      if (!isAccountOwner) {
        return;
      }

      transactions.value = await loadTransactions($store, currentDateRange.value);
    }

    function dateRangeChanged() {
      context.refs.loadingLayer.triggerInit();
    }

    function csvExport() {
      const fileName = `transactions-${currentDateRange.value.name.toLowerCase().replace(' ', '-')}.csv`;
      const csvContents = getCsvContents();
      const blob = new Blob([csvContents], {
        type: 'text/csv;charset=utf-8;',
      });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    function getCsvContents() {
      const transactionRows = transactions.value.map((transaction) => {
        let propertyName = transaction.unit ? transaction.unit.property.short_address : '';
        const listingOwnerFullName = transaction.unit ? transaction.unit.property.listing_manager.full_name : '';

        if (transaction.unit && transaction.unit.name) {
          propertyName += ` - ${transaction.unit.name}`;
        }

        return [
          transaction.formatted_created_at,
          transaction.amount,
          propertyName,
          listingOwnerFullName,
          transaction.service,
          transaction.receipt_url,
          transaction.hosted_invoice_url,
        ];
      });

      const rows = [
        ['Date', 'Amount', 'Property', 'Listing owner', 'Service', 'Invoice'],
        ...transactionRows,
      ];

      return rows.map((row) => row.map((item) => {
        if (item === null) {
          return '""';
        }
        if (item.search(/("|,|\n)/g) >= 0) {
          return `"${item}"`;
        }
        return item;
      }).join(',')).join('\n');
    }
  },
};
</script>

<style lang="scss" scoped>
.el-select {
    width: 200px;

    .el-input .el-input__inner {
        color: gray-color('dark');
    }
}
</style>
