import momentUtil from '@/utils/MomentUtil';
import get from 'lodash.get';
import { TransactionServicesEnum } from '@/constants/Transaction';
import Tour from '@/constants/Tour';

function getDateRanges(store) {
  const businessCreatedAt = store.getters['Auth/business'].created_at;
  const iteratorDate = momentUtil(businessCreatedAt, momentUtil.tz.guess());
  const nextMonth = momentUtil(null, momentUtil.tz.guess()).add(1, 'month');
  const dateRanges = [];

  while (!iteratorDate.isSame(nextMonth, 'month')) {
    dateRanges.unshift({
      name: iteratorDate.format('MMMM YYYY'),
      from: iteratorDate.startOf('month').toServerFormat(),
      to: iteratorDate.endOf('month').toServerFormat(),
    });
    iteratorDate.add(1, 'month');
  }

  return dateRanges;
}

async function loadTransactions(store, givenDateRange = null) {
  const currentDateRange = givenDateRange || getDateRanges(store)[0];

  return (await store.dispatch('Business/getTransactions', {
    from: currentDateRange.from,
    to: currentDateRange.to,
  })).filter((transaction) => {
    // exclude free no shows
    if (transaction.tour && transaction.amount === 0 && transaction.tour.status === Tour.STATUS_NO_SHOW) {
      return false;
    }
    return true;
  }).map((transaction) => {
    const isBundled = transaction.tour && transaction.amount === 0
      && [Tour.STATUS_COMPLETED, Tour.STATUS_TECHNICAL_ISSUE].includes(transaction.tour.status)
      && !transaction.tour.is_taken_over;

    let service = get(TransactionServicesEnum, transaction.type, 'Other');
    if (resolveListingShieldService(service, transaction)) {
      service += get(TransactionServicesEnum, 'additional_listing_shield', 'Other');
    }
    return {
      ...transaction,
      formatted_created_at: momentUtil(transaction.created_at, momentUtil.tz.guess()).toDisplayFormatMonthDayAndYear(),
      service,
      amount: isBundled ? 'Included in plan' : `$${transaction.amount.toFixed(2)}`,
    };
  });
}

function resolveListingShieldService(service, transaction) {
  return service !== get(TransactionServicesEnum, 'listing_shield', 'Other')
    && transaction.unit_showing_profile?.lease_period.has_listing_shield
    && transaction.amount > 0
    && service === get(TransactionServicesEnum, 'Activation', 'Activation + Phone line');
}

export { getDateRanges, loadTransactions };
