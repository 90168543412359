const Transaction = {
  TYPE_ACTIVATION: 'activation',
  TYPE_ACTIVATION_WITH_PHONE_LINE: 'activation_with_phone_line',
  TYPE_PHONE_LINE: 'phone_line',
  TYPE_SUBSCRIPTION_CHARGE: 'subscription.charge',
  TYPE_TOUR_CHARGE: 'tour_charge',
  TYPE_OPEN_HOUSE_CHARGE: 'open_house_charge',
  TYPE_INSPECTION_CHARGE: 'inspection_charge',
  TYPE_MANUAL_CHARGE: 'manual_charge',
  TYPE_BUSINESS_BALANCE: 'business_balance',
  TYPE_DIRECT_CHARGE: 'direct_charge',
  TYPE_LISTING_SHIELD: 'listing_shield',
  TYPE_AI_IDENTITY_VERIFICATION: 'ai_identity_verification',
  TYPE_ADDITIONAL_LISTING_SHIELD: 'additional_listing_shield',
};

const TransactionStatuses = {
  TYPE_SUBSCRIPTION_ITEM: 'subscription_item',
};

const TransactionServicesEnum = {
  [Transaction.TYPE_ACTIVATION]: 'Listing Activation',
  [Transaction.TYPE_ACTIVATION_WITH_PHONE_LINE]: 'Listing Activation + Phone line',
  [Transaction.TYPE_PHONE_LINE]: 'Phone line',
  [Transaction.TYPE_SUBSCRIPTION_CHARGE]: 'Subscription',
  [Transaction.TYPE_TOUR_CHARGE]: 'Showing',
  [Transaction.TYPE_OPEN_HOUSE_CHARGE]: 'Open house',
  [Transaction.TYPE_INSPECTION_CHARGE]: 'Condition report',
  [Transaction.TYPE_MANUAL_CHARGE]: 'Manual charge',
  [Transaction.TYPE_BUSINESS_BALANCE]: 'Account recharge',
  [Transaction.TYPE_DIRECT_CHARGE]: 'Account recharge',
  [Transaction.TYPE_LISTING_SHIELD]: 'Listing shield',
  [Transaction.TYPE_AI_IDENTITY_VERIFICATION]: 'AI Identity Verification',
  [Transaction.TYPE_ADDITIONAL_LISTING_SHIELD]: ' + Listing shield',
};

export { TransactionServicesEnum, Transaction, TransactionStatuses };
