<template>
  <div class="row no-gutters">
    <div
      v-for="transaction in transactions"
      :key="transaction.id"
      class="col-12 transaction"
    >
      <div class="row no-gutters">
        <div class="col-12 font-11 text-secondary mb-2">
          {{ transaction.formatted_created_at }}
        </div>
        <div class="col">
          <div class="row align-items-center">
            <div class="col">
              <div
                v-if="transaction.unit"
                class="font-15"
              >
                <router-link
                  :to="{ name: 'app.units.show', params: { id: transaction.unit.property_id, unit_id: transaction.unit.id }}"
                  class="property-link"
                >
                  {{ transaction.unit.property.short_address }}
                  <span
                    v-if="transaction.unit.name"
                    class="unit-badge ml-1 text-gray-darker"
                  >
                    {{ transaction.unit.name }}
                  </span>
                </router-link>
              </div>
            </div>
            <div class="col-auto">
              <strong
                v-if="shouldDisplayAmount(transaction)"
                class="font-17"
              >
                {{ transaction.amount }}
              </strong>
            </div>
          </div>
          <div class="mt-2" />
          <div class="row gutter-0.5">
            <div class="col">
              <div
                v-if="transaction.unit"
                class="font-13"
              >
                <img
                  class="listing-manager-avatar"
                  :src="transaction.unit.property.listing_manager.avatar_file_url"
                >
                {{ transaction.unit.property.listing_manager.full_name }}
              </div>
            </div>
            <div class="col-auto font-13">
              {{ transaction.service }}
            </div>
          </div>
        </div>
        <div class="col-auto">
          <ElDropdown
            trigger="click"
            hide-on-click
            class="pointer ml-2"
          >
            <div>
              <i class="sdicon-more-vert font-24 text-gray-dark" />
            </div>
            <ElDropdownMenu
              class="more-options-dropdown"
            >
              <ElDropdownItem>
                <a
                  :href="transaction.receipt_url"
                  target="_blank"
                >
                  View receipt
                </a>
              </ElDropdownItem>
            </ElDropdownMenu>
          </ElDropdown>
        </div>
      </div>
    </div>
    <div
      v-if="!transactions.length"
      class="col-12 text-center text-gray-dark"
    >
      No transactions
    </div>
  </div>
</template>

<script>

import { TransactionStatuses } from '@/constants/Transaction';

export default {
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    return {
      shouldDisplayAmount,
    };

    function shouldDisplayAmount(transaction) {
      return transaction.status !== TransactionStatuses.TYPE_SUBSCRIPTION_ITEM;
    }
  },
};
</script>

<style lang="scss" scoped>
.transaction {
    padding: 1rem 0 !important;
    border-bottom: 1px solid gray-color(light);
}
.unit-badge {
    border-radius: $app-border-radius;
    padding: 0.25rem 0.375rem;
    font-size: 13px;
    background: theme-color(light-grey);
}
.listing-manager-avatar {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    vertical-align: middle !important;
    margin-right: 0.25rem;
}
.property-link {
    color: gray-color(darker);
}
</style>
