<template>
  <ElTable
    :data="transactions"
    :show-header="true"
  >
    <el-table-column
      label="Date"
      prop="formatted_created_at"
      min-width="40"
    />
    <el-table-column
      label="Amount"
      min-width="40"
    >
      <template slot-scope="{ row: transaction }">
        <strong v-if="shouldBeDisplayed(transaction)">{{ transaction.amount }}</strong>
      </template>
    </el-table-column>
    <el-table-column
      label="Property"
      min-width="120"
    >
      <template
        v-if="transaction.unit"
        slot-scope="{ row: transaction }"
      >
        <router-link
          :to="{ name: 'app.units.show', params: { id: transaction.unit.property_id, unit_id: transaction.unit.id } }"
          class="property-link"
        >
          {{ transaction.unit.property.short_address }}
          <span
            v-if="transaction.unit.name"
            class="unit-badge ml-1 text-gray-darker"
          >
            {{ transaction.unit.name }}
          </span>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="Ordered by">
      <template
        v-if="transaction.unit"
        slot-scope="{ row: transaction }"
      >
        <img
          class="listing-manager-avatar"
          :src="get(transaction, 'inspection.associated_business_user.avatar_file_url', transaction.unit.property.listing_manager.avatar_file_url)"
        >
        {{ get(transaction, 'inspection.associated_business_user.full_name',
               transaction.unit.property.listing_manager.full_name) }}
      </template>
    </el-table-column>
    <el-table-column
      label="Service"
      prop="service"
      min-width="110"
    />
    <el-table-column
      label="Invoice"
      min-width="35"
    >
      <template
        v-if="Boolean(getReceiptUrl(transaction))"
        slot-scope="{ row: transaction }"
      >
        <div class="text-center">
          <a
            :href="getReceiptUrl(transaction)"
            target="_blank"
          >
            <i class="sdicon-external-link font-24" />
          </a>
        </div>
      </template>
    </el-table-column>
  </ElTable>
</template>

<script>
import get from 'lodash.get';
import { TransactionStatuses } from '@/constants/Transaction';

export default {
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    return {
      get,
      getReceiptUrl,
      shouldBeDisplayed,
    };
    function getReceiptUrl(transaction) {
      return transaction.receipt_url ?? transaction.hosted_invoice_url ?? transaction.charge?.receipt_url ?? null;
    }

    function shouldBeDisplayed(transaction) {
      return transaction.status !== TransactionStatuses.TYPE_SUBSCRIPTION_ITEM;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-table ::v-deep {
  .el-table__header-wrapper thead th {
    background: theme-color(light-grey);
    color: gray-color(darker);
    font-size: 13px;
    font-weight: $strong;
    padding: 0.5rem 0;
  }

  .el-table__body {
    font-size: 15px;
  }
}

.unit-badge {
  border-radius: $app-border-radius;
  padding: 0.25rem 0.375rem;
  font-size: 13px;
  background: theme-color(light-grey);
}

.listing-manager-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  vertical-align: middle !important;
  margin-right: 0.25rem;
}

.el-table__row:not(:hover) .property-link {
  color: gray-color(darker);
}
</style>
